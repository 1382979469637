import React from "react";
import { useState } from "react";
import Service from "../../services/user.service";
import { Button, Container, Textarea } from "@mantine/core";
var lang;
export const Lang = () => {
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("typing");

  if (status === "success") {
    return <div className="booxlang container"> <Box style={{ backgroundColor: '#333', borderRadius: 4, color: '#eee', minHeight: 100, padding: 12, width: 300}}>{lang} </Box> </div>;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus("submitting");
    try {
      await submitForm(answer);
      setStatus("success");
    } catch (err) {
      setStatus("typing");
      setError(err);
    }
  }
  function Box({ children, ...props }) {
    return <div {...props}>{children}</div>
  }
  function handleTextareaChange(e) {
    setAnswer(e.target.value);
  }

  return (
    <>
      <h2 className="head">Language Identifier</h2>
      <div className="input" >
      <Container style={{ width: 50 + "%" }} >
       
          <Textarea
            placeholder="NextParagraph will indentify the language in your text. Start by writing or pasting something here and then press the identify button. Example: नमस्ते or Bonjour"
            variant="filled"
            radius="xl"
            size="lg"
            autosize
            required
            minRows={10}
            value={answer}
            onChange={handleTextareaChange}
            disabled={status === "submitting"}
          />
          <br />
          <Button
          size="xl"
            onClick={handleSubmit}
            disabled={answer.length === 0 || status === "submitting"}
          >
            Identify
          </Button>
          {error !== null && <p className="Error">{error.message}</p>}{" "}
        
      </Container>
      </div>
    </>
  );
};

async function submitForm(answer) {
  lang = await (await (await Service.lang(answer)).data.language_list[0].name);
  console.log(lang);
}