import React from "react";

export const Devs = () => {
  return (
    <div>
    <div className="container head">
      <h1>Developer</h1>
    </div>
  <div>
    <h3 className="heleft">Rishiraj Behki:</h3><h4 className="heleft"> Billabong High International School Vadodara student. I am currently in 11th grade and am interested in web development and information security. </h4>
    </div> </div>
  );
};
