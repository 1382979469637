import axios from "axios";  

const Service = {
    summarize: async function (text) {
        const formdata = new FormData();
        formdata.append("key", process.env.REACT_APP_KEY);
        formdata.append("txt", text);
        formdata.append("sentences", 5);
       const URL = 'https://api.meaningcloud.com/summarization-1.0'
        return axios.post(URL,formdata)    
    },
    lang: async function(text){
        console.log(process.env.key)
        const formdata = new FormData();
        formdata.append("key", process.env.REACT_APP_KEY);
        formdata.append("txt", text);
       const URL = 'https://api.meaningcloud.com/lang-4.0/identification'
        return axios.post(URL,formdata)     
      
}
};
export default Service;