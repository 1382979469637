import React from "react";

export const About = () => {
  return (
    <div className="container head">
      <h1>About</h1>
      <p className="left">NextParagraph is a tool that assists you in summarising texts so that they are easier and faster to understand. This allows you to complete reading-intensive projects more quickly.<br/> NextParagraph can also determine the language of a given text.</p>
    </div>
  );
};
